<template>
    <header v-if="title !== 'semHeader'">
        <div class="header">
            <transition name="fade">
                <a v-if="hasArrow" @click="$router.go(-1)" class="arrow-back">
                    <img src="./assets/img/arrow-back.svg" alt="refresh-image" />
                </a>
            </transition>
            <transition name="fade">
                <a v-if="hasMenu" @click="drawerVisible = true">
                    <img src="./assets/img/menu-black.svg" alt="refresh-image" />
                </a>
            </transition>
            <p>
                <strong>{{ title }}</strong>
            </p>
            <button id="btnRefresh" :class="{ 'refresh-none': hasNoRefresh }" type="button" @click="sincronizaDados">
                <img src="./assets/img/refresh.svg" alt="refresh-image" />
                <span id="attNumber" v-if="getNumerosSync > 0">{{ getNumerosSync }}</span>
            </button>
        </div>
    </header>

    <template v-if="drawerVisible">
        <transition name="fade">
            <div>
                <div class="left-drawer" :style="{ width: drawerVisible ? '50%' : '0' }">
                    <div class="containerMenuDrawer">
                        <a @click="drawerVisible = false">
                            <img src="./assets/img/close-white.svg" alt="refresh-image" />
                        </a>
                        <h1 class="title-menu">Menu</h1>
                    </div>
                    <router-link
                        @click="drawerVisible = false"
                        class="btnRouter"
                        :class="this.$route.name == 'alert' ? 'activeRoute' : 0"
                        :to="{ name: 'alert' }"
                    >
                        <img src="./assets/img/file-text.svg" alt="refresh-image" />
                        <h1>Tarefas</h1>
                    </router-link>
                    <router-link
                        @click="drawerVisible = false"
                        class="btnRouter"
                        :class="this.$route.name == 'produtos' ? 'activeRoute' : 0"
                        :to="{ name: 'produtos' }"
                    >
                        <img src="./assets/img/folder-plus.svg" alt="refresh-image" />
                        <h1>Inclusão</h1>
                    </router-link>
                    <router-link
                        @click="drawerVisible = false"
                        class="btnRouter"
                        :class="this.$route.name == 'pesaveis' ? 'activeRoute' : 0"
                        :to="{ name: 'pesaveis' }"
                    >
                        <img src="./assets/img/scale.svg" alt="refresh-image" />
                        <h1>Pesáveis</h1>
                    </router-link>
                </div>
                <div class="drawer-mask"></div>
            </div>
        </transition>
    </template>

    <template v-if="isLogin">
        <header-principal :hasNoRefresh="true" titulo="Login"></header-principal>
    </template>

    <mensagem-alerta @closeModal="closeModal" :mensagem="mensagem"></mensagem-alerta>

    <router-view v-slot="slotProps">
        <transition name="slide" mode="out-in">
            <component :is="slotProps.Component"></component>
        </transition>
    </router-view>

    <tela-sync v-if="sincronizing" @closePopUp="closePopUp" :msgSync="msgSync"></tela-sync>

    <!-- <template v-if="!isSave && !isLogin">
        <footer v-if="title !== 'semHeader'">
            <router-link class="format-footer validade" :class="{ 'footer-branco': isProduto }" :to="{ name: 'alert' }">
                <img v-if="isProduto" src="./assets/img/clock-grey.svg" alt="relogio" />
                <img v-else src="./assets/img/clock.svg" alt="relogio" />
                <h3>Alertas de Validade</h3>
            </router-link>
            <router-link
                class="format-footer inclusao"
                :class="[{ 'footer-branco': isAlerta }, { 'footer-active': isProduto }]"
                :to="{ name: 'produtos' }"
            >
                <img v-if="isAlerta" src="./assets/img/add.svg" alt="relogio" />
                <img v-else src="./assets/img/add-white.svg" alt="relogio" />
                <h3>Incluir Produtos</h3>
            </router-link>
        </footer>
    </template> -->

    <template v-if="!isSave && !isLogin">
        <footer v-if="title !== 'semHeader'">
            <div class="container-footer">
                <h1 class="h1-footer">Valid</h1>
                <h2 class="h2-footer">: tecnologia oferecida por</h2>
                <img src="./assets/img/logo-minus.png" alt="minus-logo" class="img-footer" />
            </div>
        </footer>
    </template>

    <div class="btn-save" v-if="isLogin">
        <button class="salvar" type="submit" form="formLogin">Entrar</button>
    </div>
</template>

<script>
import HTTP from './http/http.js';
import HeaderPrincipal from './components/HeaderPrincipal';
import MensagemAlerta from './components/MensagemAlerta.vue';
import TelaSync from './components/TelaSync.vue';
import amplitude from 'amplitude-js';

export default {
    components: {
        MensagemAlerta,
        HeaderPrincipal,
        TelaSync,
    },
    data() {
        return {
            title: 'Valid',
            drawerVisible: false,
            hasArrow: false,
            hasMenu: false,
            hasNoRefresh: false,
            isProduto: false,
            isAlerta: true,
            isSave: false,
            mensagem: {},
            isLogin: false,
            sincronizing: false,
            counter: 0,
            msgSync: 'Sincronizando... Por favor, aguarde.',
        };
    },
    computed: {
        getNumerosSync() {
            return this.$store.getters.getNumerosSync;
        },
    },
    methods: {
        sincronizaDados() {
            if (navigator.onLine) {
                amplitude.getInstance().init('cc18428fb641f8113524c1b649cce0e8');
                let event = 'Botão Sincronizar';
                amplitude.getInstance().logEvent(event);
                this.sincronizing = true;

                this.enviaInclusao();

                this.enviaEditInclusao();

                this.enviaTarefas();

                this.enviaTarefasPesaveis();

                setTimeout(() => {
                    this.sincronizing = false;

                    if (this.counter > 0) {
                        this.mensagem = {
                            isSend: true,
                            body: `${this.counter} sincronizações geraram erro. Certifique sua internet e sincronize novamente. `,
                            error: true,
                        };
                    } else {
                        this.mensagem = {
                            isSend: true,
                            body: 'Dados sincronizados com sucesso!',
                            error: false,
                        };
                    }

                    setTimeout(() => {
                        this.mensagem.isSend = false;
                        this.counter = 0;
                    }, 3000);
                }, 4000);
            } else {
                this.mensagem = {
                    isSend: true,
                    body: 'Você precisa estar conectado a internet.',
                    error: true,
                };
            }
        },

        enviaInclusao() {
            let produtosSalvos = JSON.parse(localStorage.getItem('inclusao'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('inclusao');

            let produtosNaoEnviados = [];

            if (produtosSalvos) {
                produtosSalvos.forEach((data) => {
                    HTTP.post(`/supermaxi/${loja}/tarefas-inclusao`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('inclusao', dataToLS);
                        });
                });
            }
        },

        enviaEditInclusao() {
            let produtosEditados = JSON.parse(localStorage.getItem('inclusaoEdit'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('inclusaoEdit');

            let produtosNaoEnviados = [];

            if (produtosEditados) {
                produtosEditados.forEach((data) => {
                    HTTP.patch(`/supermaxi/${loja}/tarefas-inclusao`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('inclusaoEdit', dataToLS);
                        });
                });
            }
        },

        enviaTarefas() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('alertasValidade'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('alertasValidade');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`/supermaxi/${loja}/tarefas/multiplas`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('alertasValidade', dataToLS);
                        });
                });
            }
        },

        enviaTarefasPesaveis() {
            let tarefasSalvos = JSON.parse(localStorage.getItem('tarefasPesaveis'));
            let loja = sessionStorage.getItem('loja');
            localStorage.removeItem('tarefasPesaveis');

            let produtosNaoEnviados = [];

            if (tarefasSalvos) {
                tarefasSalvos.forEach((data) => {
                    HTTP.patch(`/supermaxi/${loja}/pesaveis/tarefas`, data)
                        .then(() => {
                            this.$store.dispatch('minusNumeroSync');
                        })
                        .catch(() => {
                            this.counter++;
                            produtosNaoEnviados.push(data);

                            let dataToLS = JSON.stringify(produtosNaoEnviados);

                            localStorage.setItem('tarefasPesaveis', dataToLS);
                        });
                });
            }
        },

        closePopUp() {
            this.sincronizing = false;
        },

        closeModal() {
            this.mensagem.isSend = false;
        },
    },
    watch: {
        $route: function (val) {
            this.title = val.meta.title;
            this.hasArrow = val.meta.hasArrow;
            this.hasMenu = val.meta.hasMenu;
            this.hasNoRefresh = val.meta.hasNoRefresh;
            this.isProduto = val.meta.isProduto;
            this.isAlerta = val.meta.isAlerta;
            this.isSave = val.meta.isSave;
            this.isLogin = val.meta.isLogin;
        },
    },
};
</script>

<style>
:root {
    --vermelho: #d21216;
    --vermelho-claro: #df5a5d;
    --vermelho-escuro: #9e0e14;
    --azul: #223b78;
    --branco: #ffffff;
    --preto: #000;
    --cinza: #cdcdcd;
    --cinza-escuro: #5f6368;
}

* {
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-weight: 400;
}

body {
    width: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
    overscroll-behavior-y: contain;
}

.title-menu {
    font-weight: 700;
    color: #fff;
    font-size: 20px;
    text-align: center;
}

.activeRoute {
    background-color: #56b88f;
}

.btnRouter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom: 1px solid #fff;
}

.btnRouter h1 {
    color: #fff;
    font-size: 20px;
    padding-left: 10px;
    text-align: center;
}

.left-drawer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    height: 100vh;
    border-right: 1px solid whitesmoke;
    background: #0f1628;
    z-index: 200;
    padding-top: 10px;
}

.drawer-mask {
    position: absolute;
    right: 0;
    top: 0;
    width: 0; /* initially */
    height: 100vh;
    opacity: 0.3;
    z-index: 199;
}

.containerMenuDrawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15%;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 10px;
    /* box-shadow: 0 3px 2px #fff; */
    border-bottom: 1px solid #fff;
}

.containerMenuDrawer a {
    display: flex;
    align-items: center;
}

.container-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    max-height: 55px;
    position: absolute;
    bottom: -5px;
    left: -10px;
    padding: 5px 0;
    right: -10px;
    margin: 5px 10px;
    border-top: 1px solid #cdcdcd;
}

.h1-footer {
    font-size: 18px;
    color: #56bb8f;
}

.h2-footer {
    font-size: 16px;
    color: #56bb8f;
}

.img-footer {
    width: 60px;
    margin-left: 5px;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100vw);
}

.slide-enter-to,
.slide-leave-from {
    transform: translateX(0px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-both-enter-from {
    transform: translateX(-100vw);
}

.slide-both-leave-to {
    transform: translateX(100vw);
}

.slide-both-enter-to,
.slide-both-leave-from {
    transform: translateX(0px);
}

.slide-both-enter-active,
.slide-both-leave-active {
    transition: all 0.4s ease-in-out;
}

header {
    width: 100%;
    display: flex;
    min-height: 56px;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

.header {
    width: 100%;
    align-items: center;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--cinza);
}

.header p {
    font-size: 16px;
}

.header button a {
    background-color: #fff;
}

#logo {
    /* display: flex; */
    display: none;
}

#logo img {
    width: 40px;
}

.header a img {
    width: 25px;
}

.header button {
    cursor: pointer;
    border: none;
}

.header button:hover {
    position: relative;
    top: 5px;
}

.header p strong {
    font-size: 15px;
}

.arrow-back {
    cursor: pointer;
    display: flex;
    align-items: center;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--vermelho);
    box-shadow: 0 0 5px #cdcdcd;
}

footer a img {
    width: 18px;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.format-footer h3 {
    font-size: 15px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

.inclusao h3 {
    color: #4e4e4e;
}

.footer-active h3 {
    color: var(--branco);
}

.footer-branco {
    background-color: #fff;
    color: #4e4e4e;
}

.footer-branco h3 {
    color: #4e4e4e;
}

#btnRefresh {
    background-color: transparent;
    display: flex;
}

#btnRefresh img {
    width: 25px;
}

#btnRefresh.refresh-none {
    display: none;
}

#attNumber {
    border-radius: 30%;
    position: absolute;
    bottom: 3px;
    font-size: 10px;
    right: 3px;
    background-color: var(--vermelho);
    color: #fff;
    padding: 3px;
}

.btn-save {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px #cdcdcd;
    justify-content: flex-end;
}

.salvar {
    cursor: pointer;
    min-width: 64px;
    border: none;
    padding: 20px 45px;
    background-color: #fff;
    color: var(--vermelho);
    font-weight: 600;
    font-size: 20px;
}

.salvar:hover {
    box-shadow: none;
    background-color: var(--vermelho);
    color: #fff;
}

@media (max-width: 319px) {
    .format-footer h3 {
        font-size: 13px;
    }

    .header p {
        font-size: 14px;
    }

    .header a img {
        width: 22px;
    }

    #btnRefresh img {
        width: 22px;
    }
}

@media (max-width: 300px) {
    .format-footer h3 {
        font-size: 12px;
    }
}
</style>
