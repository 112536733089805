const pesaveisStore = {
    state() {
        return {
            tarefasPesaveis: '',
            categoriaPesaveis: '',
            categoria4Pesaveis: '',
            categoriasPesaveis: '',
            codigo_de_barras: '',
            validade: '',
            embalagens: [],
            arrayEmbalagens: [],
            produtoPesaveis: null,
            produtosPesaveis: [],
        };
    },
    mutations: {
        setTarefasPesaveis(state, payload) {
            state.tarefasPesaveis = payload;
        },
        setCategoriaPesaveis(state, payload) {
            state.categoriaPesaveis = payload;
        },
        setCategoria4Pesaveis(state, payload) {
            state.categoria4Pesaveis = payload;
        },
        setCategoriasPesaveis(state, payload) {
            state.categoriasPesaveis = payload;
        },
        setCodigoPesaveis(state, payload) {
            state.codigo_de_barras = payload;
        },
        setValidadePesaveis(state, payload) {
            state.validade = payload;
        },
        setEmbalagens(state, payload) {
            state.embalagens = payload;
        },
        setArrayEmbalagens(state, payload) {
            state.arrayEmbalagens = payload;
        },
        setProdutoPesaveis(state, payload) {
            state.produtoPesaveis = payload;
        },
        setProdutosPesaveis(state, payload) {
            state.produtosPesaveis = payload;
        },
    },
    actions: {
        setTarefasPesaveis(context, payload) {
            context.commit('setTarefasPesaveis', payload);
        },
        setCategoriaPesaveis(context, payload) {
            context.commit('setCategoriaPesaveis', payload);
        },
        setCategoria4Pesaveis(context, payload) {
            context.commit('setCategoria4Pesaveis', payload);
        },
        setCategoriasPesaveis(context, payload) {
            context.commit('setCategoriasPesaveis', payload);
        },
        setCodigoPesaveis(context, payload) {
            context.commit('setCodigoPesaveis', payload);
        },
        setValidadePesaveis(context, payload) {
            context.commit('setValidadePesaveis', payload);
        },
        setEmbalagens(context, payload) {
            context.commit('setEmbalagens', payload);
        },
        setArrayEmbalagens(context, payload) {
            context.commit('setArrayEmbalagens', payload);
        },
        setProdutoPesaveis(context, payload) {
            context.commit('setProdutoPesaveis', payload);
        },
        setProdutosPesaveis(context, payload) {
            context.commit('setProdutosPesaveis', payload);
        },
    },
    getters: {
        getTarefasPesaveis(state) {
            return state.tarefasPesaveis;
        },

        getProdutoPesaveis(state) {
            return state.produtoPesaveis;
        },

        getProdutosPesaveis(state) {
            return state.produtosPesaveis;
        },

        getArrayEmbalagens(state) {
            return state.arrayEmbalagens;
        },

        getEmbalagens(state) {
            return state.embalagens;
        },

        getArrayCategoriasPesaveis(state) {
            return state.categoriasPesaveis;
        },

        getCategoriasPesaveis(state) {
            let prod = state.tarefasPesaveis;

            return prod.reduce(function (key, element) {
                key[element.categoria_app_nivel_1] = key[element.categoria_app_nivel_1] || [];
                key[element.categoria_app_nivel_1].push(element);
                return key;
            }, Object.create(null));
        },

        getCategorias4Pesaveis(state) {
            let prod = state.tarefasPesaveis;

            let filtrados = prod.filter((element) => {
                return element.categoria_app_nivel_1 == state.categoriaPesaveis;
            });

            return filtrados.reduce(function (key, element) {
                key[element.categoria_app_nivel_2] = key[element.categoria_app_nivel_2] || [];
                key[element.categoria_app_nivel_2].push(element.categoria_app_nivel_2);
                return key;
            }, Object.create(null));
        },

        getTarefasCategoriaPesaveis(state) {
            let filtrados = state.tarefasPesaveis.filter((element) => {
                return element.categoria_app_nivel_1 == state.categoriaPesaveis;
            });

            let filtrados2 = filtrados.filter((element) => {
                return element.categoria_app_nivel_2 == state.categoria4Pesaveis;
            });

            return filtrados2.reduce(function (key, element) {
                key[element.categoria_app_nivel_2] = key[element.categoria_app_nivel_2] || [];
                key[element.categoria_app_nivel_2].push(element);
                return key;
            }, Object.create(null));
        },

        getTotalTarefasPesaveis(state) {
            return state.tarefasPesaveis.length;
        },
        getCategoriaPesaveis(state) {
            return state.categoriaPesaveis;
        },
        getCategoria4Pesaveis(state) {
            return state.categoria4Pesaveis;
        },
        getTarefaPesaveis(state) {
            let prod = state.tarefasPesaveis;

            let filtrados = prod.filter((element) => {
                return element.codigo_de_barras == state.codigo_de_barras && element.data_validade == state.validade;
            });

            return filtrados[0];
        },
    },
};

export default pesaveisStore;
